.App {
  /* text-align: center; */
}

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
} */

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */
/* 
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.term {
  background-color: #aaaaaa;
  overflow: hidden;
  position: absolute;
  font-size: 12pt;
  /* height: 9px; */
  border-radius: 2px;
  border: 1px solid black;
  box-sizing: border-box;

}

.term-info {
  background-color: #aaaaaa;
  border: 1px solid black;
  display: none;
  position: absolute;
  z-index: 2;
  width: 200;
  text-align: center;
}

.term:hover + .term-info, .term:active + .term-info {
  display: block;
}
.S {
  background-color:#ED1B34;
}

.M {
  background-color: #019cdb;
}

.SD {
  background-color: #fedf09;
}

.C {
  background-color: #016a39;
}

.V {
  background-color: #b00000;
}

.NYD {
  background-color: #ffff00;
}

.KD {
  background-color: #005ea1;
}

.L, .FP {
  background-color: #006ab3;
}

.MP {
  background-color: #53a045;
}